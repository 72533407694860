
const dayjs = require('dayjs')

import Intercom from '@intercom/messenger-js-sdk';

function iniciarIntercom(){
    Intercom({
        app_id: 'tgvox9ou',
        user_id: localStorage.getItem('idusuario'), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: localStorage.getItem('usuario'), // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: localStorage.getItem('email'), // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: dayjs().unix(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
}

export  {iniciarIntercom}