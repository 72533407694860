<template> 
  <div style="min-width:100%;height:100%;background-color:#d1deeb" >
    
    <Bar style="height:10%!important;" />

    <v-dialog v-model="exibeModalFeedback" max-width="600px" persistent>
      <v-card width="600px">
        <v-toolbar color="primary"><v-tooblar-title style="color: white;">{{perguntaFeedback.titulo}}</v-tooblar-title>
        <!-- <v-spacer></v-spacer><v-icon color="red" @click="exibeModalFeedback = false">mdi-close-circle</v-icon> -->
        </v-toolbar>
        
        <v-card-text>
          <v-row no-gutters class="ma-1" v-if="perguntaFeedback.curtir_nao_curtir !== true">
            <v-col cols="12" sm="12" style="margin-top:10px;">
              <p style="font-size:20px;font-weight:500;"> {{perguntaFeedback.pergunta}} </p>
            </v-col>
            <v-col cols="12" sm="12" style="margin-top:10px;">
              <textarea name="" id="" cols="60" rows="10" v-model="respostaFeedback" style="width:100%; border:1px solid black;padding:10px;" placeholder="Digite sua Resposta aqui"></textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="8"></v-col>
                <v-col cols="12" sm="4">
                      <v-btn elevation="" color="success" large style="width:100%;" @click="responderFeedback(perguntaFeedback)">Responder</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="ma-1" v-else>
            <v-col cols="12" sm="12" style="margin-top:10px;">
              <p style="font-size:20px;font-weight:500;"> {{perguntaFeedback.pergunta}} </p>
            </v-col>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="6">
                      <v-btn elevation="" color="error" large style="width:100%;" @click="responderFeedbackDeslike(perguntaFeedback)">
                        <v-icon color="white" style="margin-right:10px;margin-top:5px;">mdi-thumb-down</v-icon>
                        Não Curti
                      </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                      <v-btn elevation="" color="success" large style="width:100%;" @click="responderFeedbackLike(perguntaFeedback)">
                        <v-icon color="white" style="margin-right:10px;margin-top:5px;">mdi-thumb-up</v-icon>
                        Curtir
                      </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="ma-1">

          <!-- <v-row no-gutters v-for="pdv in listaPdvAssoc" :key="pdv.id" >
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                SIM
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nome && pdv.nome.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomesub && pdv.nomesub.toUpperCase() }}
              </v-card>
            </v-col>
          </v-row> -->
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>


    <div style="min-width:100%;height:90%!important;padding:10px;background-color:#d1deeb">
        <router-view />
    </div>

   </div>
</template>

<script>

import Bar from '../../components/layout/Bar'
import {BUILD_ID_CLIENTE} from '../../constantes.js'

import {iniciarIntercom} from './importacaoTelecom.js'

export default {
  name: 'App',
  components:{
    Bar
  },
  data: () => ({
    titulo:'Card',
    exibeModalFeedback:false,
    perguntaFeedback:[],
    respostaFeedback:null,
    idclientebuild:null,
  }),
  methods:{

    getNotificacoesCount(){

          this.$http("/pdv/notificacoesCount/2")
              .then((resp) =>{
                  
                  this.$store.dispatch("set_qtdenotificacao", resp.data.length)
              })
              .catch((error) =>{
                  alert(error)
              })
              
    },

    async getFeedBack(){
      try {

        console.log('getFeedBack ',BUILD_ID_CLIENTE)
        
        await this.$http("/ambiente/getdadosambiente").then(resp => {
            console.log('resp.data ',resp.data)
            this.idclientebuild = resp.data.BUILD_ID_CLIENTE
        }).catch(error => alert(error))


        this.$http.post("https://apies.economysoftware.com.br/feedback/buscarFeedBackPendentes",{id_cliente:this.idclientebuild,
                                                                                  id_usuario:localStorage.getItem('idusuario'),id_sistema:2}).then((resp) =>{
                  if(resp.data?.length > 0){
                    this.perguntaFeedback = resp.data[0]
                    this.exibeModalFeedback = true
                  }else{
                    this.exibeModalFeedback = false
                    this.perguntaFeedback = []
                  }
              })
              .catch(() =>{
                  this.exibeModalFeedback = false
                  this.perguntaFeedback = []
              })
      } catch (error) {
          this.exibeModalFeedback = false
          this.perguntaFeedback = []
      }
    },
    responderFeedback(dadospergunta){

        try {

              if(this.respostaFeedback && this.respostaFeedback.length >= 3){
                console.log('respondendo para cliente ',BUILD_ID_CLIENTE)
                this.$http.post("https://apies.economysoftware.com.br/feedback/responderFeedback",{id_cliente:this.idclientebuild,
                                                                                id_feedback:dadospergunta.id,resposta:this.respostaFeedback,
                                                                                id_usuario:localStorage.getItem('idusuario'),
                                                                                nome_usuario:localStorage.getItem('nome')}).then(() =>{
                    this.exibeModalFeedback = false
                    this.respostaFeedback = null
                    this.getFeedBack()
                })
                .catch(() =>{
                    this.exibeModalFeedback = false
                    this.perguntaFeedback = []
                    this.respostaFeedback = null
                })
              }else{
                alert('Que tal informar uma Resposta um poquinho maior?')
              }

        } catch (error) {
            this.exibeModalFeedback = false
            this.perguntaFeedback = []
            this.respostaFeedback = null
        }
    },
    responderFeedbackDeslike(dadospergunta){

        try {
              console.log('respondendo para cliente deslike ',BUILD_ID_CLIENTE)

              this.$http.post("https://apies.economysoftware.com.br/feedback/responderFeedback",{id_cliente:this.idclientebuild,
                                                                              id_feedback:dadospergunta.id,resposta:'Não Curti',
                                                                              id_usuario:localStorage.getItem('idusuario'),
                                                                              nome_usuario:localStorage.getItem('nome')}).then(() =>{
                  this.exibeModalFeedback = false  
                  this.respostaFeedback = null                                                                              
                  this.getFeedBack()
              })
              .catch(() =>{
                  this.exibeModalFeedback = false
                  this.perguntaFeedback = []
                  this.respostaFeedback = null
              })

        } catch (error) {
            console.log(error)
            this.exibeModalFeedback = false
            this.perguntaFeedback = []
            this.respostaFeedback = null
        }
    },
    responderFeedbackLike(dadospergunta){

      try {
              console.log('respondendo para cliente like ',BUILD_ID_CLIENTE)
              this.$http.post("https://apies.economysoftware.com.br/feedback/responderFeedback",{id_cliente:this.idclientebuild,
                                                                              id_feedback:dadospergunta.id,resposta:'Curti',
                                                                              id_usuario:localStorage.getItem('idusuario'),
                                                                              nome_usuario:localStorage.getItem('nome')}).then(() =>{
                  this.exibeModalFeedback = false
                  this.respostaFeedback = null
                  this.getFeedBack()
              })
              .catch(() =>{
                  this.exibeModalFeedback = false
                  this.perguntaFeedback = []
                  this.respostaFeedback = null
              })

      } catch (error) {
          this.exibeModalFeedback = false
          this.perguntaFeedback = []
          this.respostaFeedback = null
      }
    }

  },
  mounted(){

    iniciarIntercom()
    
    if(!localStorage.getItem('pessoaLogada') && !localStorage.getItem('pessoaLogada') === 'false'){
      this.getNotificacoesCount()
    }
    
    this.$socket.io.opts.query.tipo = 'CARDAPIO'
		this.$socket.io.opts.query.tipo_usuario = 'ADM'
    this.$socket.io.opts.query.usuario = localStorage.getItem('usuario')
		this.$socket.io.opts.query.idhotel = localStorage.getItem('idhotel')
    this.$socket.connect();

    try {
      // Desabilitando Feedback temporariamente
      // this.getFeedBack()  
    } catch (error) {
      console.log(error)
    }
    
  }
};
</script>

<style>
    .bodygenerico{
        width: 100%;
        min-height: 90vh!important;
        padding:0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: pink; */
    }

    .bodyFlex{
      min-width: 100%!important;
      height: 100%!important;
      max-height: 100%!important;
      /* background-color: pink; */
      display: flex;
    }

</style>
