<template>
  <div>
      <v-card style="max-width: 100%">
           <v-toolbar color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <span style="width:95%;color:white;font-weight:bold;" >
                      Itens 
                  </span>
                  
                  <v-btn small elevation="" style="margin-right:20px;" color="" @click="fecharPedido()">
                    {{textoBotaoFinalizarPedido}}
                  </v-btn>
                  <v-btn v-if="this.exibirBotaoAcessoBarAuxiliar" small elevation="" style="margin-right:20px;" color="" @click="fecharPedidoBar()">
                    Liberar Pedido Bar
                  </v-btn>
                  <v-btn small elevation="" style="margin-right:20px;" color="" @click="transferirComanda()">Transferir mesa</v-btn>
                  <v-btn small elevation="" style="margin-right:20px;" color="" @click="imprimirComanda()">Imprimir</v-btn>
                  <span style="width:5%;"> 
                      <v-btn x-small color="red" @click="fechar()" title="Fechar">
                          <v-icon x-small style="color:white;">mdi-close</v-icon>
                      </v-btn>
                  </span>
              </v-flex>
          </v-toolbar>
          <!-- {{listaItens}} -->
          <v-card-text>
              <v-card style="padding:10px;margin-bottom:10px;">
                  <div style="width:100%;">
                     <v-data-table
                      :headers="headers"
                      :items="pedido" 
                      >
                    <template v-slot:[`item.precounitario`]="{item}">
                      <p>{{item.precounitario | formatarMoeda}}</p>
                    </template>
                    <template v-slot:[`item.valortotal`]="{item}">
                      <p>{{item.valortotal | formatarMoeda}}</p>
                    </template>
                      </v-data-table>
                  </div>
                  <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                       <b>TOTAL</b>: {{totalComanda | formatarMoeda}}
                  </div>
                  <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                       <v-btn color="error" @click="fechar()">
                          FECHAR
                      </v-btn>
                  </div>
              </v-card>
          </v-card-text>
      </v-card>
      <v-dialog v-model="modelTransferirComanda" width="700" max-width="80vw">
        <div class="modelTransferirComanda">
            <h2>Digite a mesa para qual deseja transferir essa comanda</h2>
            <input class="inputNumeroMesa" type="number" v-model="numeroMesaTransferir" />
            <div>
            <button type="button" class="buttonConcluirTransferenciasucesso" @click="concluirTransferencia">Concluir transferência de mesa</button>
            <button type="button" class="buttonConcluirTransferencia" @click="() => modelTransferirComanda = false">Cancelar</button>
            </div>
        </div>
      </v-dialog>
  </div>
</template> 

<script>



export default {
  name: 'ModalPedidosComanda',
  props:["pedido", 'mesa'],
  components:{ 
      
  },
  data: () => ({
      textoBotaoFinalizarPedido:'',
      listaItens:null,
      headers:[
                  { text: 'Comanda', value: 'idcomanda' },
                  { text: 'Produto', value: 'nomecd' },
                  { text: 'Cadeira', value: 'numero_cadeira' },
                  { text: 'Preço Un.', value: 'precounitario' },
                  { text: 'Qtde', value: 'quantidade' },
                  { text: 'Total', value: 'valortotal' },
              ],
      pedidoSelecionado:null,
      exibirBotaoAcessoBarAuxiliar:false,
      totalComanda:0,
      modelTransferirComanda: false,
      numeroMesaTransferir: null,
  }),
  async mounted(){
      this.atualizaBtnFinalizar()
      let retorno = await this.getProdutosdoPedido(Number(this.pedido[0].idcomanda))
      this.listaItens = retorno.listadepedidos
      this.totalComanda = 0
      for(let i=0,len=this.listaItens.length;i< len; i++){
          // this.totalComanda += (parseFloat(this.listaItens[i].precounitario)*parseFloat(this.listaItens[i].quantidade))
          this.totalComanda += (parseFloat(this.listaItens[i].valortotal)*parseFloat(this.listaItens[i].quantidade))
      }
     
  },
  watch:{
      async pedido(){
          this.atualizaBtnFinalizar()
          let retorno = await this.getProdutosdoPedido(this.pedido.numpedido)
          this.listaItens = retorno.listadepedidos
      }
  },
  methods:{
      atualizaBtnFinalizar(){
          let idstatuspedido = this.pedido[0].idstatuscomanda
          this.exibirBotaoAcessoBarAuxiliar = false
          if(idstatuspedido == '2'){
            this.textoBotaoFinalizarPedido = "Liberar Pedido"
            this.paginadirecionarFinalizarPedido = 'Caixa'
            
          }

          if( idstatuspedido == '3' ){
            this.textoBotaoFinalizarPedido = "Liberar Pedido Cozinha"
            this.paginadirecionarFinalizarPedido = 'Cozinha'
            this.exibirBotaoAcessoBarAuxiliar = true

          }

          if(  idstatuspedido == '10' ){
            this.textoBotaoFinalizarPedido = "Liberar Pedido"
            this.paginadirecionarFinalizarPedido = 'Cozinha'
          }

          if( idstatuspedido == '11'){
            this.textoBotaoFinalizarPedido = "Liberar Pedido"
            this.paginadirecionarFinalizarPedido = 'Bar'
          }
          
          if(  idstatuspedido == '4' ){
            this.textoBotaoFinalizarPedido = "Liberar Pedido"
            this.paginadirecionarFinalizarPedido = 'Bar'
          }

          if(  idstatuspedido == '8' ){
            this.textoBotaoFinalizarPedido = "Liberar Pedido"
            this.paginadirecionarFinalizarPedido = 'Cozinha'
          }
          
          if(this.pedido[0].idstatuscomanda == '9'){
            this.textoBotaoFinalizarPedido = "Entregar Pedido"
            this.paginadirecionarFinalizarPedido = 'Garcom'
            
          }

          if(this.pedido[0].idstatuscomanda == '5' && this.pedido[0].fl_comanda_fechada == false){
            this.textoBotaoFinalizarPedido = "Fechar Comanda"
            this.paginadirecionarFinalizarPedido = 'Pendentes'
          }
      },
      imprimirComanda() {
          // this.showModal = true
          let objPedido = this.pedido 
          objPedido.itens = this.listaItens 
          this.$emit('imprimir', objPedido)
          // setTimeout(() => this.$htmlToPaper('modalImpressao'), 1000);
      },
      direcionar(){
          this.$router.push(this.url)
      },
      fechar(){
          this.$emit('fechar');
      },

      transferirComanda() {
        this.modelTransferirComanda = true;
      },

      fecharPedido() {
          this.abrirPagina(this.paginadirecionarFinalizarPedido)
      },
      fecharPedidoBar() {
          this.abrirPagina('Bar')  
      },

      abrirPagina(nomepagina){
            const routeData = this.$router.resolve({name: nomepagina});
            // window.open(routeData.href+'?numpedido='+this.pedido[0].idcomanda, '_blank');
            // window.open(routeData.href+'?numpedido='+this.pedido[0].idcomanda);
            this.$router.push(routeData.href+'?numpedido='+this.pedido[0].idcomanda)
      },

      async concluirTransferencia() {
        if(this.numeroMesaTransferir === null || this.numeroMesaTransferir === 0) {
            return global.alert('Digite o número de uma mesa válida');
        }
        const idusuario = Number(localStorage.getItem('idusuario'))
        const idcomanda  = this.pedido[0].idcomanda;
        const data = {
             mesaAtual: Number(this.mesa),
             mesaFinal: Number(this.numeroMesaTransferir)
        }
        this.$http.post(`mesaStatus/alterarMesaDaComanda/${idusuario}/${idcomanda}`, data).then(()=>{
          global.alert('Comanda transferida com sucesso')
          this.modelTransferirComanda = false;
          this.$emit('fechar');
          this.$emit('fechar-modal-comandas');
          this.numeroMesaTransferir = null;
            })
            .catch((error)=>{
             console.log(error)
            })
      },

      async getProdutosdoPedido(numpedido){
          let objetoRetorno = {}
          await this.$http('pdv/getProdutosdoPedido/'+numpedido+'/t').then((resp) =>{
            objetoRetorno.valorTotal = 0
            objetoRetorno.numpedido = resp.data[0].idcomanda
            objetoRetorno.datafeito = resp.data[0].datahoraabertura
            objetoRetorno.datarecebido = resp.data[0].datahoraabertura
            objetoRetorno.imgassinatura = resp.data[0].imgassinatura
            objetoRetorno.idtipolocal = resp.data[0].idtipolocal
            objetoRetorno.numerolocal = resp.data[0].numerolocal
            objetoRetorno.numreserva = resp.data[0].numreserva
            objetoRetorno.coduh = resp.data[0].coduh
            objetoRetorno.nome = resp.data[0].nome
            objetoRetorno.sobrenome = resp.data[0].sobrenome
            objetoRetorno.nomegarcom = resp.data[0].nomegarcom
            objetoRetorno.listadepedidos = resp.data
            for(var i = 0, len = resp.data.length; i < len; i++){
                objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
            }
          }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
          })
          return objetoRetorno
      },
     
  },
}
</script>


<style scoped>
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: table;
transition: opacity 0.3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
width: 300px;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
transition: all 0.3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.botaoModalLocal{
  width:100%;
  height: 50px;
  display: block;
  margin-bottom:30px;
  border:0px;
  font-weight: bold;
  color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


.inputsHorario{
  background-color:white;
  text-align:center;
  border-radius:15px;
  min-height:8vh;
  font-size:25px;
  padding:10px;
  border: 1px solid #819ab4;
  margin-right:10px;
  width: 100px;
}

.modelTransferirComanda {
    background-color: rgb(255, 255, 255);
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.inputNumeroMesa {
    background-color: white;
    border: 1px solid black;
    height: 40px;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonConcluirTransferencia {
    background-color: #b21515;
    width: 250px;
    height: 40px;
    color: white;
    border-radius: 5px;
    margin-right: 5px;
}

.buttonConcluirTransferenciasucesso {
    background-color: #0aa82f;
    width: 250px;
    height: 40px;
    color: white;
    border-radius: 5px;
    margin-right: 5px;
}
</style>

