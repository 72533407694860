import { render, staticRenderFns } from "./CardapioPedido.vue?vue&type=template&id=6b2db561&scoped=true&"
import script from "./CardapioPedido.vue?vue&type=script&lang=js&"
export * from "./CardapioPedido.vue?vue&type=script&lang=js&"
import style0 from "./CardapioPedido.vue?vue&type=style&index=0&id=6b2db561&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2db561",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VSpacer})
