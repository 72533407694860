<template>
    <v-card  elevation="2" outlined  style="padding-bottom:5px!important;min-height:95%;margin-top:1%;padding:10px;" >
        <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
            <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1OeSH4PeEHFk3wHQ-znuMieL6Qzr3oQi9/preview"/>
        </div>
            <v-card-text style="">
                
                    <div style="display:flex;">
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-aprovacao-caixa" v-model="config.aprovacaocaixa" data-cy="config.aprovacaocaixa"> </v-switch>
                                <label for="sw-aprovacao-caixa" style="font-size:15px;cursor:pointer;">Aprovação do Caixa?</label>
                                <span v-if="config.aprovacaocaixa" style="display: flex; align-items: center;margin-left: 1em;" data-cy="span-limiteaprovacaocaixa">
                                    <label style="font-size:15px; margin-right: 1em;">Até que horas?</label>
                                    <v-text-field type="time" outlined hide-details dense v-model="config.limiteaprovacaocaixa" data-cy="config.limiteaprovacaocaixa"> </v-text-field>
                                </span>
                            </div>
                        </div>
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-aprovacao-bar" v-model="config.aprovacaobar" data-cy="config.aprovacaobar"> </v-switch>
                                <label for="sw-aprovacao-bar" style="font-size:15px;cursor:pointer;">Aprovação do Bar?</label>
                            </div>
                        </div>
                        
                    </div>
    
    
                    <div style="display:flex;margin-top:30px;">
    
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-aprovacao-cozinha" v-model="config.aprovacaocozinha" data-cy="config.aprovacaocozinha"> </v-switch>
                                <label for="sw-aprovacao-cozinha" style="font-size:15px;cursor:pointer;">Aprovação da Cozinha?</label>
                            </div>
                        </div>
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-exibir-taxas-aos-clientes" v-model="config.exibirTaxasAosClientes" data-cy="config.exibirTaxasAosClientes"> </v-switch>
                                <label for="sw-exibir-taxas-aos-clientes" style="font-size:15px;cursor:pointer;">Exibir Taxas?</label>
                            </div>
                        </div>
                        
                    </div>
                          
                    <div style="display:flex;margin-top:30px;">
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-cartao-consumo" v-model="config.cartaoconsumo" data-cy="config.cartaoconsumo"> </v-switch>
                                <label for="sw-cartao-consumo" style="font-size:15px;cursor:pointer;">Cartão de Consumo Obrigatorio?</label>
                            </div>
                        </div>
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-pedidop-comanda-pendente" v-model="config.permitirPedidoComandaPendente" data-cy="config.exibirTaxasAosClientes"> </v-switch>
                                <!-- <label for="sw-permitir-pedidop-comanda-pendente" style="font-size:15px;cursor:pointer;">Bloquear Pedido de Outro local se existir comanda aberta em outro Local?</label> -->
                                <label for="sw-permitir-pedidop-comanda-pendente" style="font-size:15px;cursor:pointer;">Bloquear Comandas abertas de locais diferentes?</label>
                            </div>
                        </div>
    
    
                    </div>
    
                    <div style="display:flex;margin-top:30px;">
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-fechar-comandas-checkout" v-model="config.fecharComandasCheckout" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <label for="sw-fechar-comandas-checkout" style="font-size:15px;cursor:pointer;">Fechar comandas no dia do checkout do hóspede?</label>
                            </div>
                        </div>
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-fechar-comandas-fora-horario" v-model="config.fecharComandasForaHorario" data-cy="config.fecharComandasForaHorario"> </v-switch>
                                <!-- <label for="sw-fechar-comandas-fora-horario" style="font-size:15px;cursor:pointer;">Fechar comandas automaticamente quando permanecerem abertas 1h. após horário do garçon?</label> -->
                                <label for="sw-fechar-comandas-fora-horario" style="font-size:15px;cursor:pointer;">Fechar comandas 1h apos fim do expediente do garçom?</label>
                            </div>
                        </div>
    
                    </div>
    
                    <div style="display:flex;margin-top:30px;">
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.permitirExclusaoItemGarcom" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <!-- <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Permitir exclusão de item da comanda pelo garçom?</label> -->
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Garçom pode excluir itens da comanda?</label>
                            </div>
                        </div>
    
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.permitirFechamentoPassanteGarcom" data-cy="config.fecharPassante"> </v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Garçom pode fechar conta de passante?</label>
                            </div>
                        </div>
    
                    </div>

    
                    <div style="display:flex;margin-top:30px">
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.permitirGarcomCadastrarPassante" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Garçom pode cadastrar Passantes?</label>
                            </div>
                        </div>

                        

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.exibirProdutosForaHorario" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Exibir produtos fora de horário de disponibilidade?</label>
                            </div>
                        </div>

                        
    
                        <!-- <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.permitirFechamentoPassanteGarcom" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Permitido fechamento de conta passante pelo Garçom?</label>
                            </div>
                        </div> -->
    
                    </div>

                    <div style="display:flex;margin-top:30px">

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch id="sw-permitir-exclusao-item-garcom" v-model="config.comandaComMesaUnica" data-cy="config.fecharComandasCheckout"> </v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Mesa apenas com uma comanda</label>
                            </div>
                        </div>

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;" v-show="tipoIntegracao.toLowerCase() === 'hits'">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.integracaoTempoReal" 
                                    data-cy="config.fecharComandasCheckout" 
                                    @click="config.integracaoTempoReal ? modalConfirmacaoIntegracaoTempoReal = true : ''"
                                ></v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Integracao em tempo de produção</label>
                            </div>
                        </div>
    
                    </div>

                    <div style="display:flex;margin-top:30px;">

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.exibirTelaBaixas" 
                                    data-cy="config.exibirTelaBaixas"
                                ></v-switch>
                                <label for="sw-permitir-exclusao-item-garcom" style="font-size:15px;cursor:pointer;">Exibir tela de baixas</label>
                            </div>
                        </div>

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.comandasMeiaNoite" 
                                    data-cy="config.comandasMeiaNoite" 
                                ></v-switch>
                                <label for="state" style="font-size:15px;cursor:pointer;">Novas comandas criadas a partir de meia noite?</label>
                            </div>
                        </div>
    
                    </div>

                    <div style="display:flex;margin-top:30px;margin-bottom:60px;">

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.descontoFinalComanda" 
                                    data-cy="config.descontoFinalComanda"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Possibilidade de descontar total da comanda?</label>
                            </div>
                        </div>


                         <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.acessohospededireto" 
                                    data-cy="config.acessohospededireto"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Hospede acessa Cardapio Direto sem Login</label>
                            </div>
                        </div>
    
                    </div>

                    <div style="display:flex;margin-top:30px;margin-bottom:60px;">

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.hospedepedidobloqueio" 
                                    data-cy="config.hospedepedidobloqueio"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Hospede Não pode Fazer Pedidos</label>
                            </div>
                        </div>

                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.contadorItensComanda" 
                                    data-cy="config.contadorItensComanda"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Contador de itens da comanda?</label>
                            </div>
                        </div>


                         
    
                    </div>

                    <div style="display:flex;margin-top:30px;margin-bottom:60px;">
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.notificarContasFechadas" 
                                    data-cy="config.notificarContasFechadas"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Notificar contas fechadas?</label>
                            </div>
                        </div>

                         <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.imprime_pospronto" 
                                    data-cy="config.contadorItensComanda"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Permitir Impressao Completa apos pedido entregue( antes de fechar a comanda)?</label>
                            </div>
                        </div>

                    </div>
                    <div style="display:flex;margin-top:30px;margin-bottom:60px;">
                        <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.entregar_e_fechar_comanda_web" 
                                    data-cy="config.notificarContasFechadas"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Tela Garçom Web - Entregar e fechar a comanda ao mesmo tempo?</label>
                            </div>
                        </div>

                         <!-- <div style="height:3vh;margin-top:2px;width:50%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;cursor:pointer;">
                                <v-switch 
                                    v-model="config.imprime_pospronto" 
                                    data-cy="config.contadorItensComanda"
                                ></v-switch>
                                <label style="font-size:15px;cursor:pointer;">Permitir Impressao Completa apos pedido entregue( antes de fechar a comanda)?</label>
                            </div>
                        </div> -->

                    </div>

                    


    <!-- 
                    <div style="display:flex;margin-top:60px;">
    
                        <div style="margin-top:20px;width:100%;padding-right:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:flex-end;flex-direction:row;">
                                <label for="tempominuto" style="font-size:20px;margin-bottom:10px;width:40%;">Tempo max Caixa(min) </label>
                                <input id="tempominuto" v-model="tempominuto" data-cy="tempominuto" maxlength="50" type="text"  placeholder="Tempo em minutos" style="width:60%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            </div>
                        </div>
    
                        
    
                    </div> -->




    
                    <div style="height:50px;">
                        <div style="padding-bottom:0px;display:flex;align-items:center;">
                            <div style="width:40%;display:flex;align-items:center;">
                                <label style="font-size:15px;">Tempo max Caixa(min)</label>
                            </div>
                            <div style="width:60%;padding-left:10px;">
                                <input id="tempominuto" v-model="tempominuto" data-cy="tempominuto" maxlength="50" type="text"  placeholder="Tempo em minutos" style="width:100%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            </div>
                        </div>
                    </div>

                    <div style="display:flex;margin-top:0px;">
    
                       <div style="margin-top:30px;width:100%;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                <label style="font-size:15px;width:40%;">Locais que este aparelho receberá notificação</label>
                                <v-select
                                    v-model="config.locaisNotificacao" 
                                    :items="listaLocais" 
                                    multiple
                                    dense
                                    outlined 
                                    attach
                                    small-chips
                                    style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                </v-select>
                            </div>
                        </div>
    
                        
    
                    </div>
    
                    <div style="display:flex;margin-top:0px;">
    
                       <div style="margin-top:30px;width:100%;">
                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                <label style="font-size:15px;width:40%;">Apos Pedido Pronto?</label>
                                <v-select v-model="fluxoAposPedidoPronto" :items="listaDeFluxoPedidoPronto" 
                                    item-text="nome" item-value="id" return-object dense outlined 
                                    style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                </v-select>
                            </div>
                        </div>
    
                        
    
                    </div>
    
    
                    <div style="height:50px;margin-top:40px;">
                        <div style="padding-bottom:2px;display:flex;align-items:center;">
                            <div style="width:40%;display:flex;align-items:center;">
                                <label style="font-size:15px;">Fluxo de Impressão</label>
                            </div>
                            <div style="width:60%;">
                                <v-select  v-model="fluxoImpressaoSelecionado" :items="listadefluxoimpressao" 
                                    item-text="nome" item-value="id" return-object dense outlined 
                                    style="width:100%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                </v-select>
                               
                            </div>
                        </div>
                    </div>
    
                    <div v-if="fluxoImpressaoSelecionado && fluxoImpressaoSelecionado.id == 1">
    
                    
                            <div style="height:50px;margin-top:5px;">
                                <div style="padding-bottom:2px;display:flex;align-items:center;">
                                    <div style="width:40%;display:flex;align-items:center;">
                                        <v-switch v-model="impressaoCaixa" data-cy="config.assinaturaobrigatoria"> </v-switch>
                                        <label style="font-size:18px;">Imprimir no Caixa?</label>
                                    </div>
                                    <div style="width:60%;">
                                        <!-- <input id="tempominuto" v-model="ipImpressoraCaixa" data-cy="tempominuto" maxlength="50" type="text"  placeholder="IP da Impressora do Caixa" style="margin-left:10px;width:57%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                                        <v-select  v-model="impressoraCaixa" :items="listadeimpressoras" 
                                            item-text="nome" item-value="ip" return-object dense outlined 
                                            @change="()=>{ this.ipImpressoraCaixa = this.impressoraCaixa.ip }"
                                            style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
    
                            <div style="height:50px;margin-top:5px;">
                                <div style="padding-bottom:2px;display:flex;align-items:center;">
                                    <div style="width:40%;display:flex;align-items:center;">
                                        <v-switch v-model="impressaoCozinha" data-cy="config.assinaturaobrigatoria"> </v-switch>
                                        <label style="font-size:18px;">Imprimir na Cozinha?</label>
                                    </div>
                                    <div style="width:60%;">
                                        <!-- <input id="tempominuto" v-model="ipImpressoraCozinha" data-cy="tempominuto" maxlength="50" type="text"  placeholder="IP da Impressora da Cozinha" style="margin-left:10px;width:57%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                                        <v-select  v-model="impressoraCozinha" :items="listadeimpressoras" 
                                            item-text="nome" item-value="ip" return-object dense outlined 
                                            @change="()=>{ this.ipImpressoraCozinha = this.impressoraCozinha.ip }"
                                            style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
    
                            <div style="height:50px;margin-top:5px;">
                                <div style="padding-bottom:2px;display:flex;align-items:center;">
                                    <div style="width:40%;display:flex;align-items:center;">
                                        <v-switch v-model="impressaoBar1" data-cy="config.assinaturaobrigatoria"> </v-switch>
                                        <label style="font-size:18px;">Imprimir no Bar 1?</label>
                                    </div>
                                    <div style="width:60%;">
                                        <!-- <input id="tempominuto" v-model="ipImpressoraBar1" data-cy="tempominuto" maxlength="50" type="text"  placeholder="IP da Impressora do Bar 1" style="margin-left:10px;width:57%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                                        <v-select  v-model="impressoraBar1" :items="listadeimpressoras" 
                                            item-text="nome" item-value="ip" return-object dense outlined
                                            @change="()=>{ this.ipImpressoraBar1 = this.impressoraBar1.ip }"
                                            style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
    
                            <div style="height:50px;margin-top:5px;">
                                <div style="padding-bottom:2px;display:flex;align-items:center;">
                                    <div style="width:40%;display:flex;align-items:center;">
                                        <v-switch v-model="impressaoBar2" data-cy="config.assinaturaobrigatoria"> </v-switch>
                                        <label style="font-size:18px;">Imprimir no Bar 2?</label>
                                    </div>
                                    <div style="width:60%;">
                                        <!-- <input id="tempominuto" v-model="ipImpressoraBar2" data-cy="tempominuto" maxlength="50" type="text"  placeholder="IP da Impressora do Bar 2" style="margin-left:10px;width:57%;height:40px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                                        <v-select  v-model="impressoraBar2" :items="listadeimpressoras" 
                                            item-text="nome" item-value="ip" return-object dense outlined 
                                            @change="()=>{ this.ipImpressoraBar2 = this.impressoraBar2.ip }"
                                            style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
    
                    </div>
    
                    
                    <!-- 
                        <div style="height:42vh;max-height:20vh;overflow:auto;margin-top:20px;">
                            <div style="padding-bottom:2px;display:flex;align-items:flex-start;flex-direction:column;">
                                <label for="tempominuto" style="font-size:20px;margin-bottom:10px;">Tempo maximo de Espera para o Aceite do Caixa (em minutos) </label>
                                <input id="tempominuto" v-model="tempominuto" data-cy="tempominuto" maxlength="50" type="text"  placeholder="Tempo em minutos" style="width:20%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            </div>
                        </div> 
                    -->
                    
                    <div id="salvar" style="width:100%;height:8vh;position:absulute;margin-top:20px;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                            <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                            <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                    </div>

                    <ModalConfirmacao 
                        :showModal="modalConfirmacaoIntegracaoTempoReal" 
                        textosim="Continuar" 
                        titulo="Ao ativar essa opção, esteja ciente que ao precisar remover um item da comanda, também terá que realizar o estorno no HITS
                        <br>  
                        OBS: PASSANTE CRIADO NO CARDÁPIO DIGITAL NÃO SERÃO CONSIDERADOS NESSA REGRA" 
                        textonao="Cancelar" 
                        :exibeBtnCancelar="false"
                        @sim="() => {config.integracaoTempoReal = true; modalConfirmacaoIntegracaoTempoReal = false;}" 
                        @nao="() => {config.integracaoTempoReal = false; modalConfirmacaoIntegracaoTempoReal = false;}"
                    />
            
            </v-card-text>
    
        </v-card>
    </template>
    
    <script>
    import BotaoTutorial from '../layout/BotaoTutorial.vue'
    import ModalConfirmacao from '../layout/ModalConfirmacao.vue'
    export default {
        name: 'FluxoPedido',
        components: {
            BotaoTutorial,
            ModalConfirmacao
        },
        props:[],
        async mounted(){
            this.buscarDados()

            const locaisNotificacao = JSON.parse(localStorage.getItem("locaisNotificacao"))

            if(locaisNotificacao){
                this.config.locaisNotificacao = locaisNotificacao
            } else {
                this.config.locaisNotificacao = ["CAIXA", "COZINHA", "BAR"]  
                localStorage.setItem("locaisNotificacao", JSON.stringify(this.config.locaisNotificacao))
            }

        },
        watch:{
          
        },
        data: () => ({
            tempominuto:10,
            modalConfirmacaoIntegracaoTempoReal: false,
            config:{
                aprovacaocaixa:true,
                aprovacaocozinha:true,
                aprovacaobar:true,
                limiteaprovacaocaixa: null,
                cartaoconsumo:true,
                exibirTaxasAosClientes:false,
                permitirPedidoComandaPendente:false,
                fecharComandasCheckout: true,
                fecharComandasForaHorario: true,
                permitirExclusaoItemGarcom: false,
                permitirFechamentoPassanteGarcom: false,
                permitirGarcomCadastrarPassante: false,
                exibirProdutosForaHorario: false,
                comandaComMesaUnica: false,
                integracaoTempoReal: false,
                locaisNotificacao: [],
                exibirTelaBaixas: false,
                comandasMeiaNoite: false,
                descontoFinalComanda: false,
                acessohospededireto: false,
                hospedepedidobloqueio: false,
                contadorItensComanda: false,
                notificarContasFechadas: false,
                imprime_pospronto:false,
                entregar_e_fechar_comanda_web:false,
            },
            listaLocais: ["CAIXA", "COZINHA", "BAR"],
            impressaoCaixa:false,
            ipImpressoraCaixa:'',
            impressaoCozinha:false,
            ipImpressoraCozinha:'',
            impressaoBar1:false,
            ipImpressoraBar1:'',
            impressaoBar2:false,
            ipImpressoraBar2:'',
            fluxoImpressaoSelecionado:{ id:1, nome: 'Fluxo de Perfis em Tela Cozinha / Bar' },
            listadefluxoimpressao:[
                { id:1, nome: 'Fluxo de Impressao por Perfil em Tela Cozinha / Bar' },
                { id:2, nome: 'Fluxo de Impressao por PDV' },
                { id:3, nome: 'Não Imprimir' },
            ],
            listadeimpressoras:[],
            impressoraCaixa:null,
            impressoraCozinha:null,
            impressoraBar1:null,
            impressoraBar2:null,
            fluxoAposPedidoPronto:{ id:1, nome: 'Enviar para Pedidos a Entregar' },
            listaDeFluxoPedidoPronto:[
                { id:1, nome: 'Enviar para Pedidos a Entregar' },
                { id:2, nome: 'Enviar para Comandas a Fechar' },
            ],
            tipoIntegracao: '',
            
        }),
        methods:{
            salvar(){
                if(this.validacao()){           
    
                    //let parametro = {
                    //        chave:'FLUXOPEDIDOS',
                    //        valor:this.config.aprovacaocaixa ? 'true' : 'false',
                    //        valor2:this.tempominuto,
                    //        valor3:this.config.aprovacaocaixa ? this.config.limiteaprovacaocaixa : null,
                    //        valor4:this.config.exibirTaxasAosClientes ? 'true' : 'false',
                    //        valor5:this.config.permitirPedidoComandaPendente ? 'true' : 'false',
                    //        valor6:JSON.stringify({
                    //            fecharComandasCheckout: this.config.fecharComandasCheckout, 
                    //            fecharComandasForaHorario: this.config.fecharComandasForaHorario,
                    //            permitirExclusaoItemGarcom: this.config.permitirExclusaoItemGarcom,
                    //            permitirFechamentoPassanteGarcom: this.config.permitirFechamentoPassanteGarcom,
                    //            permitirGarcomCadastrarPassante: this.config.permitirGarcomCadastrarPassante,
                    //        })
                    //    }

                    localStorage.setItem('locaisNotificacao', JSON.stringify(this.config.locaisNotificacao))

                    const parametros = [
                        {
                            chave: 'FLUXOPEDIDOS',
                            valor:this.config.aprovacaocaixa ? 'true' : 'false',
                            valor2:this.tempominuto,
                            valor3:this.config.aprovacaocaixa ? this.config.limiteaprovacaocaixa : null,
                            valor4:this.config.exibirTaxasAosClientes ? 'true' : 'false',
                            valor5:this.config.permitirPedidoComandaPendente ? 'true' : 'false',
                            valor6:JSON.stringify({
                                fecharComandasCheckout: this.config.fecharComandasCheckout, 
                                fecharComandasForaHorario: this.config.fecharComandasForaHorario,
                                permitirExclusaoItemGarcom: this.config.permitirExclusaoItemGarcom,
                                permitirFechamentoPassanteGarcom: this.config.permitirFechamentoPassanteGarcom,
                                permitirGarcomCadastrarPassante: this.config.permitirGarcomCadastrarPassante,
                            })
                        },
                        { 
                            chave:'FLUXOIMPRESSAO', 
                            valor:this.fluxoImpressaoSelecionado.id,
                            valor2: this.fluxoImpressaoSelecionado.nome 
                        },
                        { 
                            chave:'OBRIGATORIOCARTAOCONSUMO', 
                            valor:this.config.cartaoconsumo ? 'true' : 'false' 
                        },
                        {
                            chave:'IMPRESSAOCAIXA',
                            valor:this.impressaoCaixa ? 'true' : 'false',
                            valor2:this.ipImpressoraCaixa
                        },
                        {
                            chave:'IMPRESSAOCOZINHA',
                            valor:this.impressaoCozinha ? 'true' : 'false',
                            valor2:this.ipImpressoraCozinha
                        },
                        {
                            chave:'IMPRESSAOBAR1',
                            valor:this.impressaoBar1 ? 'true' : 'false',
                            valor2:this.ipImpressoraBar1
                        },
                        {
                            chave:'IMPRESSAOBAR2',
                            valor:this.impressaoBar2 ? 'true' : 'false',
                            valor2:this.ipImpressoraBar2
                        },
                        {
                            chave:'FLUXOPEDIDOPRONTO',
                            valor:this.fluxoAposPedidoPronto.id
                        },
                        {
                            chave:'FLUXOPEDIDOSEXTRA',
                            valor:this.config.aprovacaocozinha ? 'true' : 'false',
                            valor2:this.config.aprovacaobar ? 'true' : 'false'
                        },
                        {
                            chave: 'EXIBIRPRODUTOSFORAHORARIO',
                            valor: this.config.exibirProdutosForaHorario ? 'true' : 'false'    
                        },
                        {
                            chave: 'COMANDA_UNICA_MESA',
                            valor: this.config.comandaComMesaUnica ? 'true' : 'false'    
                        },
                        {
                            chave: 'INTEGRACAO_TEMPO_REAL_HITS',
                            valor: this.config.integracaoTempoReal ? 'true' : 'false'    
                        },
                        {
                            chave: 'EXIBIR_TELA_BAIXAS',
                            valor: this.config.exibirTelaBaixas ? 'true' : 'false'    
                        },
                        {
                            chave: 'NOVAS_COMANDAS_MEIA_NOITE',
                            valor: this.config.comandasMeiaNoite ? 'true' : 'false'
                        },
                        {
                            chave: 'DESCONTO_FINAL_COMANDA',
                            valor: this.config.descontoFinalComanda ? 'true' : 'false'
                        },
                        {
                            chave: 'ACESSO_HOSPEDE_DIRETO',
                            valor: this.config.acessohospededireto ? 'true' : 'false'
                        },
                        {
                            chave: 'HOSPEDE_PEDIDO_BLOQUEIO',
                            valor: this.config.hospedepedidobloqueio ? 'true' : 'false'
                        },
                        {
                            chave: 'CONTADOR_ITENS_COMANDAS',
                            valor: this.config.contadorItensComanda ? 'true' : 'false'
                        },
                        {
                            chave: 'NOTIFICAR_CONTAS_FECHADAS',
                            valor: this.config.notificarContasFechadas ? 'true' : 'false'
                        },
                        {
                            chave: 'IMPRIME_POSPRONTO',
                            valor: this.config.imprime_pospronto ? 'true' : 'false'
                        },
                        {
                            chave: 'ENTREGAR_E_FECHAR_COMANDA_WEB',
                            valor: this.config.entregar_e_fechar_comanda_web ? 'true' : 'false'
                        },
                        
                    ]

                    Promise.all(parametros.map(parametro => this.$http.put('pdv/updateparametros',parametro)))
                        .then(() => {
                            this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                            localStorage.setItem('exibir_tela_baixas', this.config.exibirTelaBaixas ? 'true' : 'false')
                        })
                        .catch(() => {
                            this.$alertar("error","Erro ao Atualizar Parametros!")
                        })

                    
                    // this.$emit('salvar',obj)
                }
            },
            async buscarDados(){
    
                this.$http.post('pdv/getImpressoras',{ativo:true})
                .then(resp =>{
    
                    if(resp.data && resp.data.length > 0){
                        this.listadeimpressoras = resp.data
                    }
                }).catch(error =>{
                    alert(error)
                })
    
    
                this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOS'})
                .then(resp =>{
                    this.config.aprovacaocaixa = resp.data[0].valor === 'true'? true : false
                    this.config.limiteaprovacaocaixa = resp.data[0].valor3
                    this.tempominuto = resp.data[0].valor2
                    this.config.exibirTaxasAosClientes =  resp.data[0].valor4 === 'true'? true : false
                    this.config.permitirPedidoComandaPendente =  resp.data[0].valor5 === 'true'? true : false
                    let fechamentoAutomatico = JSON.parse(resp.data[0].valor6);
                    if (fechamentoAutomatico) {
                        this.config.fecharComandasCheckout = fechamentoAutomatico.fecharComandasCheckout
                        this.config.fecharComandasForaHorario = fechamentoAutomatico.fecharComandasForaHorario
                        this.config.permitirExclusaoItemGarcom = fechamentoAutomatico.permitirExclusaoItemGarcom
                        this.config.permitirFechamentoPassanteGarcom = fechamentoAutomatico.permitirFechamentoPassanteGarcom
                        this.config.permitirGarcomCadastrarPassante = fechamentoAutomatico.permitirGarcomCadastrarPassante
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'EXIBIRPRODUTOSFORAHORARIO'})
                .then(resp =>{
                    this.config.exibirProdutosForaHorario = resp.data[0].valor === 'true'? true : false
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOSEXTRA'})
                .then(resp =>{
                    this.config.aprovacaocozinha = resp.data[0].valor === 'true'? true : false
                    this.config.aprovacaobar = resp.data[0].valor2 === 'true'? true : false
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'FLUXOIMPRESSAO'})
                .then(resp =>{
           
                    this.fluxoImpressaoSelecionado = {id: parseInt(resp.data[0].valor) ,nome:resp.data[0].valor2 }
                    
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'OBRIGATORIOCARTAOCONSUMO'})
                .then(resp =>{
                    this.config.cartaoconsumo = resp.data[0].valor === 'true'? true : false
                }).catch(error =>{
                    alert(error)
                })
    
    
                this.$http.post('pdv/buscarparametros',{chave:'IMPRESSAOCAIXA'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.impressaoCaixa = resp.data[0].valor === 'true'? true : false
                        this.ipImpressoraCaixa = resp.data[0].valor2
    
                        this.impressoraCaixa = {nome:'Caixa',ip:resp.data[0].valor2}
                    }
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'IMPRESSAOCOZINHA'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.impressaoCozinha= resp.data[0].valor === 'true'? true : false
                        this.ipImpressoraCozinha = resp.data[0].valor2
    
                        this.impressoraCozinha = {nome:'Cozinha',ip:resp.data[0].valor2}
                    }
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'IMPRESSAOBAR1'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.impressaoBar1 = resp.data[0].valor === 'true'? true : false
                        this.ipImpressoraBar1 = resp.data[0].valor2
    
                        this.impressoraBar1 = {nome:'Bar 1',ip:resp.data[0].valor2}
                    }
                }).catch(error =>{
                    alert(error)
                })
    
                this.$http.post('pdv/buscarparametros',{chave:'IMPRESSAOBAR2'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.impressaoBar2 = resp.data[0].valor === 'true'? true : false
                        this.ipImpressoraBar2 = resp.data[0].valor2
    
                        this.impressoraBar2 = {nome:'Bar 2',ip:resp.data[0].valor2}
                    }
                }).catch(error =>{
                    alert(error)
                })
    
    
                this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOPRONTO'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
    
                        if(resp.data[0].valor == '1'){
                            this.fluxoAposPedidoPronto = { id:1, nome: 'Enviar para Pedidos a Entregar' }
                        }
                        if(resp.data[0].valor == '2'){
                            this.fluxoAposPedidoPronto = { id:2, nome: 'Enviar para Comandas a Fechar' }
                        }
    
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'COMANDA_UNICA_MESA'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
    
                        this.config.comandaComMesaUnica = resp.data[0].valor === 'true'? true : false
    
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'INTEGRACAO_TEMPO_REAL_HITS'})
                .then(resp =>{
                    console.log(resp.data[0].valor)
                    if(resp.data && resp.data.length > 0){
    
                        this.config.integracaoTempoReal = resp.data[0].valor === 'true'? true : false
    
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'EXIBIR_TELA_BAIXAS'})
                .then(resp =>{
                    console.log(resp.data[0].valor)
                    if(resp.data && resp.data.length > 0){
                        this.config.exibirTelaBaixas = resp.data[0].valor === 'true'? true : false
                        localStorage.setItem('exibir_tela_baixas', resp.data[0].valor)
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'NOVAS_COMANDAS_MEIA_NOITE'})
                .then(resp =>{
                    console.log(resp.data[0].valor)
                    if(resp.data && resp.data.length > 0){
                        this.config.comandasMeiaNoite = resp.data[0].valor === 'true'? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'DESCONTO_FINAL_COMANDA'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.config.descontoFinalComanda = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'ACESSO_HOSPEDE_DIRETO'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.config.acessohospededireto = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'HOSPEDE_PEDIDO_BLOQUEIO'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.config.hospedepedidobloqueio = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'CONTADOR_ITENS_COMANDAS'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.config.contadorItensComanda = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'NOTIFICAR_CONTAS_FECHADAS'})
                .then(resp =>{
                    if(resp.data && resp.data.length > 0){
                        this.config.notificarContasFechadas = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'IMPRIME_POSPRONTO'})
                .then(resp =>{
                    console.log('imprime_pospronto resp ',resp)
                    if(resp.data && resp.data.length > 0){
                        this.config.imprime_pospronto = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'ENTREGAR_E_FECHAR_COMANDA_WEB'})
                .then(resp =>{
                    console.log('entregar_e_fechar_comanda_web resp ',resp)
                    if(resp.data && resp.data.length > 0){
                        this.config.entregar_e_fechar_comanda_web = resp.data[0].valor === 'true' ? true : false
                    }
                }).catch(error =>{
                    alert(error)
                })

                this.tipoIntegracao = localStorage.getItem('tipo_integracao')
    
            },
            validacao(){
              let validado = []
              if(this.config.aprovacaocaixa && this.config.limiteaprovacaocaixa == null){
                this.$alertar('warning', "É obrigatória a resposta para a pergunta 'Até que horas?'", '=/')
                validado.push(false)
              }
    
              if(validado.every(el => el == true)){
                return true
              }else{
                return false
              }
            }
        }
    }
    </script>
    
    <style scoped>
        
    </style>